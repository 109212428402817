/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */
@import "stackoverflow-light.min.css";
/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .preload * {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -ms-transition: none !important;
    -o-transition: none !important;
  }

  body {
    @apply font-sans-pro text-brand-dark;
  }

  dd {
    @apply ml-10
  }
}

.border-l-solid {
  border-left: 4px solid #9F4846;
}

@layer components {
  .post-content {
    p.lead {
      @apply text-lg xl:text-xl pt-6 xl:pt-10;
    }

    pre {
      @apply my-8 mx-4;
    }

    /* inline snippet */
    p code {
      @apply px-1 bg-gray-50;
    }

    .callout i {
      font-style: normal;
      align-self: center;

      &:before {
        display: block;
        font-size: 24px;
        @media (min-width: $large-breakpoint) {
          font-size: 42px
        }
      }

      &.icon-note {
        &:before {
          content: '🗒';
        }
      }
      &.icon-question {
        &:before {
          content: '🤔';
        }
      }
      &.icon-important {
        &:before {
          content: '❗';
        }
      }
      &.icon-info {
        &:before {
          content: '💡';
        }
      }
      &.icon-warn {
        &:before {
          content: '⚠️';
        }
      }
      &.icon-quote {
        &:before {
          content: '\201c';
        }
      }
      &.icon-error {
        &:before {
          content: '🛑';
        }
      }
    }
  }
}

#toc {
  @apply list-none;
  > li {
    @apply mb-1;
    > a {
      @apply text-base;
    }
  }
  > li > ul {
    @apply list-disc ml-4;
    > li {
      @apply mb-1;
      > a {
        @apply text-sm;
      }
    }
  }
}
